.interest-button {
  width: 25% !important;
  align-self: center;
  margin: 3% !important;
}

.removable-badge {
  cursor: pointer;
}


.selected-interests { 
  text-align: center;
  margin-top: auto !important;
  margin-bottom: 1rem;
  min-height: 12vh;
  z-index: 3;
}

.interests-container {
  height: 20vh; 
  z-index: 3;
}

.emoticon-button {
  border-radius: 50% !important; 
  width: 10vh; 
  height: 10vh; 
  display: flex;
  align-items: center  !important; 
  justify-content: center  !important; 
  text-align: center !important;
  position: relative;
  transform: translate(-50%, -50%);
}

.emoticon-button::after {
  content: attr(data-emoticon); /* Use data attribute for emoticon */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the emoticon */
  font-size: 6vh;
}

.btn-outline-primary {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.btn-outline-primary:hover {
  background-color: lightblue !important;
}

.btn-primary:hover {
  color:darkblue !important;
}

.bottom-section {
  height: 7%;
}
