
.bingo-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.empty-space {
  width: 100vw;
  height: 12.5vh;
}

.bingo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 0px;
  width: 100%;
  height: 75vh;
}

/* Bingo Cell Styling */
.bingo-cell {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  border: 1px solid black;
  position: relative;
  cursor: pointer;
  background-color: white;
  overflow: hidden;
  font-size: 1em;
  padding: 10px;
  box-sizing: border-box;
  text-align: center; /* Ensure text is centered */
  word-wrap: break-word; /* Allow words to wrap */
}

.bingo-item {
  font-size: 1em;
  z-index: 1;
}


.cross {
  position: absolute;
  font-size: 3em;
  color: green;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marked {
  background-color: #e6e6e6;
}

@media (max-width: 600px) {
  .bingo-grid {
    grid-auto-rows: 60px;
  }

  .cross {
    font-size: 2em;
  }

  .bingo-item {
    font-size: 0.8em;
  }
}
