.container {
  display: flex;
  flex-direction: column;
  position: relative; 
  height: 100vh; 
  overflow: hidden;
}

.story-titles {
  background: linear-gradient(
    to bottom, 
    rgba(255, 255, 255, 1) 0%, 
    rgba(255, 255, 255, 0.2) 100%
  );
  /* background-color: rgba(255, 255, 255, 0.65);  /* white with opacity */
  height: 18vh; 
  width: 100%;
  display: flex;
  flex-direction: column;
}

.story-title {
  height: 8vh; 
  margin-bottom: 2vh;
}

.chapter-title {
  height: 8vh; 
}

.story-title, .chapter-title {
  font-size: clamp(1rem, 3vh, 15rem) !important; /* Responsive font size */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines you want */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.story-text {
  height: 30vh; 
}

.story-options {
  height: 61vh; 
}

.story-loading {
  height: 20vh; 
  margin-top: auto !important;
  margin-bottom: 2vh !important;
  z-index: 3;
}

.story-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto !important;
  margin-bottom: 2vh !important;
  z-index: 3;
}

.story-box {
  background-color: rgba(255, 255, 255, 0.80); /* white with opacity */
  padding-top: 1vh;
  padding-right: 2vh;
  padding-left: 2vh;
  border-radius: 10px;
  overflow-y: auto;
  max-width: 80%;
}

.navigation-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh; /* Adjust as needed */
}

.nav-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 4rem; 
  color: white;
  height: 100%;
}

.nav-button.left {
  justify-content: flex-start;
  padding-left: 1vh;
}

.nav-button.right {
  justify-content: flex-end;
  padding-right: 1vh;
}

.new-story-button {
  position: absolute;
  margin-top: 1vh; 
  margin-left: 1vh;
  padding: 0 !important;
  width: 4vh !important;
  height: 4vh !important;
  align-self: flex-start; 
  font-size: 1vh !important;
  align-content: center;
}

.new-story-button2 {  
  position: absolute;  
  top: 1vh;
  left: 1vh; 
  font-size: 2.5vh; 
  cursor: pointer;
  z-index: 3; 
}

.feedback-button {  
  position: absolute;  
  top: 5vh;
  right: 1vh; 
  font-size: 2.5vh; 
  z-index: 3; 
  cursor: pointer;
}

.support-button {  
  position: absolute;  
  top: 5vh;
  left: 1vh; 
  font-size: 2.5vh; 
  z-index: 3; 
  cursor: pointer;
}

.btn-light {
  margin-top: 0.3rem !important;
  margin-bottom: 0.3rem !important;
  background-color: #007bff !important;
}

.loading-animation-story {
  display: flex;
  margin-top: 3rem;
  font-size: 2rem;
  align-self: flex-start;
  align-content: center;
  justify-content: center !important;
}

.rating-section2 {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  margin-bottom: 3vh;
  text-align: center !important;
}

.feedback-title {
  font-size: 2rem;
}

.story-bottom-section {
  margin-bottom: 10vh;
}