@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap'); /* Example font */

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storybook-title {
  align-self: center;
  font-size: 5rem;
  color: #FFFFFF;
  margin-top: 1rem;
}

.story-length-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.story-length-options button {
  margin: 10px;
  padding: 15px;
  width: 75%;
  font-size: 1.5rem; 
}

.spinner {
  border: 5px solid rgba(255, 255, 255, 0.3); /* Light grey */
  border-top: 5px solid #555; /* Darker grey */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading-animation-start {
  margin-top: 3rem;
  font-size: 2rem;
  align-self: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


