@media screen and (orientation: landscape) {
  .dimension-container {
    /* Styles for portrait mode */
    margin: 0 auto; /* Center the container */
    max-width: 50vh !important; /* set the screen ratio to 1:2 */
  }
}

@media screen and (max-aspect-ratio: 0.49) {
  .bottom-section {
    height: 15% !important;
  }
  .pagination {
    height: 15vh !important;
  }
  .story-text {
    height: 58vh !important; 
  }
}


body {
  overflow: hidden;
}

.title-font {
  font-family: 'Fredericka the Great', cursive;
}

.title-font-bold {
  font-family: 'Fredericka the Great', cursive;
  font-weight: bold;
}

.selection-container {
  display: flex;
  flex-direction: column;
  position: relative; 
  height: 100vh; 
  overflow: hidden;
}

.full-height {
  height: 85vh !important;
  max-height: 85vh !important;
}

.text-center {
  text-align: center;
  align-self: center;
  z-index: 3;
}

.text-80 {
  width: 80%;
}

.text-large2 {
  align-self: center;
  width: 70%;
  text-align: center;
  margin-top: auto !important;
  margin-bottom: 1rem !important;
  z-index: 3;
}

.text-large3 {
  align-self: center;
  width: 70%;
  text-align: center;
  margin-bottom: 1rem !important;
  z-index: 3;
}

.character-display {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 2;
}

.background {
  z-index: 1;
  pointer-events: none;
}

.character-display img {
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.next-button {
  background-color: #003366;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  width: 80%; 
  align-self: center !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  z-index: 3;
}

.pagination {
  display: flex;
  align-items: center; 
  align-self: center;
  height: 7vh;
  z-index: 3;
}

.pagination .dot {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: grey;
  margin: 0 0.5em;
}

.pagination .dot.active {
  background-color: white;
}

.selection-navigation {
  position: absolute;
  color: #003366;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  pointer-events: none;
  font-size: 7rem;
  z-index: 3;
}

.left-button, .right-button {
  pointer-events: auto;
  cursor: pointer;
}

.selection-navigation .left-button {
  margin-left: 3rem;
}

.selection-navigation .right-button {
  margin-left: auto;
  margin-right: 3rem;
}

.nav-buttons {
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 2.5em; 
  z-index: 3;
}

.back-button, .fullscreen-button {
  margin: 0 0.8em; 
  cursor: pointer;
}

.fullscreen-button-story {  
  /* here to compare with styling on the other pages for fullscreen button */
  position: absolute;  
  top: 1vh;
  right: 1vh; 
  font-size: 2.5vh; 
  cursor: pointer;
  z-index: 3; 
}

.character-description {
  text-align: center;
  font-size: 4rem; 
  font-weight: bold;
  height: 45vh; 
  width: 75%; 
  margin-top: 3vh !important;
  overflow-y: auto;
  font-family: 'Amatic SC', cursive;
  z-index: 3;
}



/* PAPER */
.button-paper {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-paper:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-paper:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}




.hidden {
  display: none !important;
}

.not-visible {
  visibility: hidden !important;
}