.word-story-text-box {
  background-color: rgba(255, 255, 255, 0.80); 
  padding-top: 2vh;
  padding-right: 2vh;
  padding-left: 2vh;
  margin-top: 5vh;
  border-radius: 10px;
  overflow-y: auto;
  width: 80%;
  height: 25vh;
  align-self: center;
  animation: fadeIn 1s ease;
}

.rectangle-button {
  height: 5vh;
  margin-bottom: 1vh;
}

.rectangle-button-options {
  height: 10vh;
  width: 28%;
  margin-left: 0.5vh;
  margin-right: 0.5vh;
}

.word-story-words {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: start;
  height: 42vh;
  overflow: hidden;
}

.word-story-option-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
