.form-group {
  margin-bottom: 3vh;
  margin-left: 5vh;
  margin-right: 5vh;
  text-align: center;
}

.form-control {
  width: 100%;
  margin-top: 5px;
}

.rating-button {
  border-radius: 50% !important; 
  width: 7vh; 
  height: 7vh; 
  display: flex;
  align-items: center  !important; 
  justify-content: center  !important; 
  text-align: center !important;
  position: relative;
}

.feedback-textarea {
  height: 27vh !important; 
}

.rating-button::after {
  content: attr(data-emoticon); /* Use data attribute for emoticon */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4vh;
}

.rating-section {
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
  text-align: center !important;
}

